<template>
    <div class="address-setting">
        <div class="address-state">
            <div class="address-province">
                <custom-select :label="$t('setting.address.labels.province')"
                               :classes="['w-full', 'my-3']"
                               styles="lineHeight: 35px;"
                               :options="provinces"
                               :default="club.province"
                               @suggest:selected="refreshCity"
                               v-model="club.province"
                               searchable/>
            </div>

            <div class="address-city">
                <custom-select :options="cities[club.province.value] || []"
                               :classes="['w-full', 'my-3']"
                               styles="lineHeight: 35px;"
                               :label="$t('setting.address.labels.city')"
                               :default="club.city"
                               :disabled="club.province.value === ''"
                               v-model="club.city"
                               searchable/>
            </div>

            <CustomValidateTextarea :label="$t('setting.address.labels.address')"
                                    align="right"
                                    :is-empty="true"
                                    :regex="addressRegex"
                                    :classes="{'w-full': true, 'my-3': true}"
                                    v-model="club.address"/>
        </div>

        <vs-button v-show="false"
                   id="editAddressSettingBTN"
                   @click="sendData"/>
    </div>
</template>

<script>
import { getClubSetting, editClubSetting } from '../../../../http/requests/setting'
import { getProvinces } from "../../../../http/requests/provinces";
import CustomSelect from "../../../../components/customSelect/customSelect";
import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea";
export default {
    name: "addressSetting",
    components: {CustomValidateTextarea, CustomSelect},
    data () {
        return {
            addressRegex: this.$validator('regex.setting.address.name'),
            provinces: [],
            cities: {},
            club: {
                province: {
                    id: '',
                    label: ''
                },
                city: {
                    id: '',
                    label: ''
                },
                address: {
                    value: '',
                    isValid: true
                }
            }
        }
    },

    created () {
        this.getSetting()
        this.getProvinces()
    },

    methods: {
        getSetting () {
            getClubSetting().then((response) => {
                const club = response.data.data
                this.club.province.id = club.address_province_id || -1
                this.club.province.label = club.address_province_label || '-'
                this.club.city.id = club.address_city_id || -1
                this.club.city.label = club.address_city_label || '-'
                this.club.address.value = club.address || ''
            })

        },
        getProvinces() {
            getProvinces().then(response => {
                const provinces = response.data.data

                this.provinces = provinces.map(province => {
                    this.cities[province.name] = province.cities.map(city => {
                        return {
                            id: city.id,
                            label: city.name,
                            value: city.name
                        }
                    })

                    return {
                        id: province.id,
                        label: province.name,
                        value: province.name
                    }
                })
            })
        },
        refreshCity() {
            this.club.city = {
                id: '',
                label: '-',
                value: '-'
            }
        },

        sendData () {
            if (this.club.address.isValid) {
                const payload = {
                    address_province_id: this.club.province.id > 0 ? this.club.province.id : null,
                    address_city_id: this.club.city.id > 0 ? this.club.city.id : null,
                    address_province_label: this.club.province.id > 0 ? this.club.province.label : null,
                    address_city_label: this.club.city.id > 0 ? this.club.city.label : null,
                    address: this.club.address.value || null
                }
                editClubSetting(payload).then(response => {
                    this.$vs.notify({
                        time: 2400,
                        title: this.$t('alert.message.title'),
                        text: this.$t('setting.notifications.editAddress.success'),
                        iconPack: 'feather',
                        icon: 'icon-check',
                        color: 'success'
                    })

                    this.$emit('edit')
                }).catch(error => {
                    this.$vs.notify({
                        time: 2400,
                        title: this.$t('alert.error.title'),
                        text: error.response.data.message || error.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .address-setting {
        padding: 0 10px;
    }
</style>
