<template>
  <vx-card class="edit-company-page" no-shadow>
    <vs-row
      vs-type="flex"
      vs-justify="space-around">

      <!-- Img Row -->
      <div class="flex flex-wrap items-center mb-base">
        <vs-row
          vs-type="flex"
          vs-justify="space-around">
          <custom-profile-image-input :default-image="require('@/assets/icons/TENNIS_COURT.svg')" v-model="clubInfo.avatar" />
        </vs-row>

        <vs-row
          vs-type="flex"
          vs-justify="center">
          <p class="w-4/5 text-xs text-center mt-2">{{ $t('setting.clubInfo.validators.logoRule') }}</p>
        </vs-row>
      </div>
    </vs-row>

    <vs-row>

      <p class="h5 text-primary">{{ $t('setting.clubInfo.labels.generalInfo') }}</p>
      <vs-divider class="my-2"></vs-divider>

      <vs-col class="w-full">

        <!-- club name input -->
        <custom-validate-input class="w-full mb-2"
                               :label="$t('setting.clubInfo.labels.name')"
                               align="right"
                               :rtl="true"
                               v-model="clubInfo.name"/>
      </vs-col>

    </vs-row>

    <vs-row>

      <p class="h5 mt-5 text-primary">{{ $t('setting.clubInfo.labels.telInfo') }}</p>
      <vs-divider class="my-2"></vs-divider>

      <vs-col class="w-full">
        <div class="address-state">
          <div class="address-province">
            <custom-select :label="$t('setting.clubInfo.labels.province')"
                           :classes="['w-full', 'my-3']"
                           styles="lineHeight: 35px;"
                           :options="provinces"
                           :default="clubInfo.province"
                           @suggest:selected="refreshCity"
                           v-model="clubInfo.province"
                           searchable/>
          </div>

          <div class="address-city">
            <custom-select :options="cities[clubInfo.province.value] || []"
                           :classes="['w-full', 'my-3']"
                           styles="lineHeight: 35px;"
                           :label="$t('setting.clubInfo.labels.city')"
                           :default="clubInfo.city"
                           :disabled="clubInfo.province.value === ''"
                           v-model="clubInfo.city"
                           searchable/>
          </div>

          <CustomValidateTextarea :label="$t('setting.clubInfo.labels.address')"
                                  align="right"
                                  :rtl="$vs.rtl"
                                  :is-empty="true"
                                  :regex="addressRegex"
                                  :classes="{'w-full': true, 'my-3': true}"
                                  v-model="clubInfo.address"/>
        </div>

        <!-- club phone number input -->
        <u-phone-input class="w-full mt-5"
                       :label="$t('setting.clubInfo.labels.phoneNumber')"
                       default-country-code="+98"
                       v-model="clubInfo.phoneNumber"/>

        <u-phone-input class="w-full mt-5"
                       :label="$t('setting.clubInfo.labels.whatsAppSupport')"
                       default-country-code="+98"
                       v-model="clubInfo.whatsAppSupport"/>

      </vs-col>
    </vs-row>

    <vs-row>

      <vs-col class="w-full">

        <!-- Save & Reset Button -->
        <div class="flex flex-wrap items-center justify-end">
          <button id="saveCompanyInfoSettingBTN" @click="sendForm" v-show="false" class="useral-action-button ml-auto mt-2" ></button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import datePicker from 'vue-persian-datetime-picker'
import {editClubSetting, getClubSetting, uploadAvatar} from '@/http/requests/setting'
import CustomIcon from '../../../../components/customIcon/customIcon'
import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput";
import UPhoneInput from "../../../../components/customInput/UPhoneInput";
import CustomValidateInput from "../../../../components/customInput/customValidateInput";
import {getAvatarUrl, hideLoading, showLoading} from "../../../../assets/js/functions";
import CustomValidateTextarea from "../../../../components/customInput/customValidateTextarea.vue";
import {getProvinces} from "../../../../http/requests/provinces";
import CustomSelect from "../../../../components/customSelect/customSelect.vue";

export default {
  name: 'clubInfoSetting',
  components: {
    CustomSelect,
    CustomValidateTextarea,
    CustomValidateInput,
    UPhoneInput,
    CustomProfileImageInput,
    CustomIcon,
    datePicker
  },
  metaInfo () {
    return {
      title: this.$t('setting.companyInfo.title')
    }
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      addressRegex: this.$validator('regex.setting.address.name'),
      provinces: [],
      cities: {},
      clubInfo: {
        avatar: {
          value: '',
          isValid: true
        },
        code: '',
        name: {
          value: '',
          isValid: true
        },
        managerName: '',
        logo: {},
        country: {
          value: '',
          isValid: true
        },
        province: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        address: {
          value: '',
          isValid: true
        },
        managerPhoneNumber: '',
        phoneNumber: {
          value: '',
          isValid: false,
        },
        whatsAppSupport: {
          value: '',
          isValid: true
        },
        financial: {
          from: '',
          to: ''
        }
      },
      activeLogoPrompt: false,
      tabActions: [
        {
          toolbar: [
            {
              id: 'saveBTN',
              // i18n: 'navbar.save',
              color: 'success',
              icon: 'icon-check',
              iconPack: 'feather'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'Settings'},
              type: 'link',
              icon: 'icon-chevron-left',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.auth.user
    }
  },
  created () {
    showLoading()

    this.getProvinces()
    this.getCompanySetting()
  },
  methods: {
    getCompanySetting() {
      getClubSetting().then(response => {
        const club = response.data.data
        this.clubInfo.avatar.value = club.club_avatar || null
        this.clubInfo.name = {
          value: club.club_name || '',
          isValid: true
        }
        this.clubInfo.address = {
          value: club.address || '',
          isValid: true
        }
        this.clubInfo.province.id = club.address_province_id || -1
        this.clubInfo.province.label = club.address_province_label || '-'
        this.clubInfo.city.id = club.address_city_id || -1
        this.clubInfo.city.label = club.address_city_label || '-'
        this.clubInfo.phoneNumber.value = club.support_number || ''
        this.clubInfo.whatsAppSupport.value = club.whatsapp_number || ''

        hideLoading()
      })
        .catch(() => {
          hideLoading()
        })
    },
    getProvinces() {
      getProvinces().then(response => {
        const provinces = response.data.data

        this.provinces = provinces.map(province => {
          this.cities[province.name] = province.cities.map(city => {
            return {
              id: city.id,
              label: city.name,
              value: city.name
            }
          })

          return {
            id: province.id,
            label: province.name,
            value: province.name
          }
        })
      })
    },
    refreshCity() {
      this.clubInfo.city = {
        id: '',
        label: '-',
        value: '-'
      }
    },
    openChooseFileDialog () {
      document.getElementById('browseLogo').click()
    },
    avatarValidation (avatar) {
      if (avatar.size > (800 * 1026)) {
        this.$vs.notify({
          time: 4000,
          title: this.$t('alert.error.title'),
          text: this.$t('setting.clubInfo.validators.imageSize'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      let logoFormat = avatar.name.split('.')
      logoFormat = logoFormat[logoFormat.length - 1].toLowerCase()
      if (logoFormat !== 'png' && logoFormat !== 'jpg' && logoFormat !== 'jpeg') {
        this.$vs.notify({
          time: 4000,
          title: this.$t('alert.error.title'),
          text: this.$t('setting.clubInfo.validators.fileFormat'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        return false
      }

      return true
    },
    uploadAvatar (event) {
      if (event.target.files && this.avatarValidation(event.target.files[0])) uploadAvatar(event.target.files[0]).then(response => {
        this.clubInfo.logo = response.data.path
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.clubInfo.notifications.logo.upload.success'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.error.title'),
          text: error.response.data.error || error.response.data.avatar,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    removeAvatar () {
      this.clubInfo.logo = ''
      this.$vs.notify({
        time: 2400,
        title: this.$t('alert.message.title'),
        text: this.$t('setting.clubInfo.notifications.logo.delete.success'),
        iconPack: 'feather',
        icon: 'icon-check',
        color: 'success'
      })
    },
    sendForm () {
      if (this.clubInfo.name.length === 0) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.clubInfo.validators.name'),
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          time: 2400,
          color: 'danger'
        })
        return false
      }
      const payload = {
        club_name: this.clubInfo.name.value,
        club_avatar: this.clubInfo.avatar.value || null,
        address_province_id: this.clubInfo.province.id > 0 ? this.clubInfo.province.id : null,
        address_city_id: this.clubInfo.city.id > 0 ? this.clubInfo.city.id : null,
        address_province_label: this.clubInfo.province.id > 0 ? this.clubInfo.province.label : null,
        address_city_label: this.clubInfo.city.id > 0 ? this.clubInfo.city.label : null,
        address: this.clubInfo.address.value || null,
        support_number: this.clubInfo.phoneNumber.value || null,
        whatsapp_number: this.clubInfo.whatsAppSupport.value || null,
      }
      editClubSetting(payload).then(response => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.message.title'),
          text: this.$t('setting.clubInfo.notifications.edit.success'),
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })
        const clubInfo = {
          club_name: this.clubInfo.name.value,
          club_logo: getAvatarUrl(this.clubInfo.avatar.value)
        }
        this.$store.dispatch('setting/setClubInfo', clubInfo)
        this.$emit('edited')
      }).catch(error => {
        this.$vs.notify({
          time: 2400,
          title: this.$t('alert.error.title'),
          text: error.response.data.message || error.response.data.error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  watch: {
    clubInfo: {
      handler (val, oldVal) {
        clearTimeout(this.firstVisitTimer)
        this.firstVisitTimer = setTimeout(() => {
          this.firstVisit = false
        }, 500)

        if (!this.firstVisit) {
          this.$store.dispatch('setPageChanges')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .edit-company-page {
    .u-input-label-box-center.outline span {
      font-size: 12px;
    }
  }
</style>
